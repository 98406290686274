import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm,message } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FileExcelOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  ReloadOutlined,
  FileMarkdownOutlined
} from '@ant-design/icons';

const PushNotifications = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData, setExcelData] = useState([]);
  let [activeUserCount, setActiveUserCount] = useState(0);
  let [loginUserCount, setLoginUserCount] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'title';
    }
    let restData = await api
      .get(`/rest/pushNotifications?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (item.sendNotification)
              item.sendNotification = <CheckOutlined />;
            else item.sendNotification = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/pushNotifications/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/pushNotifications/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let getReport = async (record) => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(
      `/rest/reports/pushNotifications?id=${record._id}&lang=${lang}`,
    );
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `${record.content.replace(/ /g, '')}_report.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.TITLE,
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title - b.title,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.LANG,
      dataIndex: 'lang',
      key: 'lang',
      sorter: (a, b) => a.lang - b.lang,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.SHOW_APP_LIST,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.NOTIFICATION,
      dataIndex: 'sendNotification',
      key: 'sendNotification',
      sorter: (a, b) => a.sendNotification - b.sendNotification,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (group) =>
        group.length > 0 &&
        group.map((e) => <div style={{ marginBottom: 5 }}>{e.name}</div>),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => getReport(record)}
          >
            {isSmall ? '' : languagesCms.REPORT}
          </Button>
          <Link to={'/pushNotifications/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // let excelNotification = async (e) => {
  //   let result = await api.post(`/rest/pushNotifications/excel`, e.target.files[0]);
  // };
  let profileSettings;

  let parseDateExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  };
  let getReportAll = async () => {
    let result = await api.get(`/rest/reports/pushNotificationsAll`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `pushNotificationsAll.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let uploadExcel = async () => {
    let attendee_id;
    let userData;
    let userGroups = [];

    let updateCount = 0;
    let insertCount = 0;

    if (excelData) {
      for (let i in excelData) {
        attendee_id = excelData[i].id;
        userGroups = [];
        if (excelData[i].group_1) {
          userGroups.push(excelData[i].group_1.toString().trim());
        }
        if (excelData[i].group_2) {
          userGroups.push(excelData[i].group_2.toString().trim());
        }
        if (excelData[i].group_3) {
          userGroups.push(excelData[i].group_3.toString().trim());
        }
        if (excelData[i].group_4) {
          userGroups.push(excelData[i].group_4.toString().trim());
        }
        if (excelData[i].group_5) {
          userGroups.push(excelData[i].group_5.toString().trim());
        }
        if (excelData[i].group_6) {
          userGroups.push(excelData[i].group_6.toString().trim());
        }
        if (excelData[i].group_7) {
          userGroups.push(excelData[i].group_7.toString().trim());
        }
        if (excelData[i].group_8) {
          userGroups.push(excelData[i].group_8.toString().trim());
        }
        if (excelData[i].group_9) {
          userGroups.push(excelData[i].group_9.toString().trim());
        }
        if (excelData[i].group_10) {
          userGroups.push(excelData[i].group_10.toString().trim());
        }
        if (excelData[i].group_11) {
          userGroups.push(excelData[i].group_11.toString().trim());
        }
        if (excelData[i].group_12) {
          userGroups.push(excelData[i].group_12.toString().trim());
        }
        if (excelData[i].group_13) {
          userGroups.push(excelData[i].group_13.toString().trim());
        }
        if (excelData[i].group_14) {
          userGroups.push(excelData[i].group_14.toString().trim());
        }
        if (excelData[i].group_15) {
          userGroups.push(excelData[i].group_15.toString().trim());
        }
        if (excelData[i].group_16) {
          userGroups.push(excelData[i].group_16.toString().trim());
        }
        if (excelData[i].group_17) {
          userGroups.push(excelData[i].group_17.toString().trim());
        }
        if (excelData[i].group_18) {
          userGroups.push(excelData[i].group_18.toString().trim());
        }
        if (excelData[i].group_19) {
          userGroups.push(excelData[i].group_19.toString().trim());
        }
        if (excelData[i].group_20) {
          userGroups.push(excelData[i].group_20.toString().trim());
        }
        if (typeof excelData[i].birthday === 'number') {
          excelData[i].birthday = moment(parseDateExcel(excelData[i].birthday))
            .add(12, 'hours')
            .format();
        }

        userData = {
          name: excelData[i].name,
          lastname: excelData[i].lastname || '',
          email: excelData[i].email || '',
          phone: excelData[i].phone || '',
          location: excelData[i].city || '',
          birthday: excelData[i].birthday || '',
          groups: userGroups,
        };

        //Ek alanlar
        if (profileSettings.showCompanyDev) {
          if (excelData[i].company) {
            userData.company = excelData[i].company.toString().trim() || '';
          } else {
            userData.company = '';
          }
        }

        if (profileSettings.showPositionDev) {
          if (excelData[i].position) {
            userData.position = excelData[i].position.toString().trim() || '';
          } else {
            userData.position = '';
          }
        }

        if (profileSettings.showDepartmentDev) {
          if (excelData[i].department) {
            userData.department =
              excelData[i].department.toString().trim() || '';
          } else {
            userData.department = '';
          }
        }

        if (profileSettings.showRegisterNoDev) {
          if (excelData[i].registerNo) {
            userData.registerNo =
              excelData[i].registerNo.toString().trim() || '';
          } else {
            userData.registerNo = '';
          }
        }

        if (profileSettings.showJobStartDateDev) {
          if (excelData[i].jobStartDate) {
            userData.jobStartDate =
              excelData[i].jobStartDate.toString().trim() || '';
          } else {
            userData.jobStartDate = '';
          }
        }

        if (profileSettings.showTcDev) {
          if (excelData[i].tc) {
            userData.tc = excelData[i].tc.toString().trim() || '';
          } else {
            userData.tc = '';
          }
        }

        if (profileSettings.showBirthdayStringDev) {
          if (excelData[i].birthdayString) {
            userData.birthdayString =
              excelData[i].birthdayString.toString().trim() || '';
          } else {
            userData.birthdayString = '';
          }
        }

        if (attendee_id) {
          // update
          await api
            .put('/rest/attendees/' + attendee_id, userData)
            .then(({ data: { result, result_message } }) => {});
          updateCount++;
        } else {
          // insert
          userData['role'] = 'user';
          userData['password'] = 'passVeli';

          await api
            .post('/rest/attendees', userData)
            .then(({ data: { result, result_message } }) => {});
          insertCount++;
        }
      }
      if (updateCount || insertCount) {
        message.error(
          'Excel Yüklendi. ' +
            updateCount +
            ' Güncellendi, ' +
            insertCount +
            ' Kayıt Eklendi.',
          2,
        );
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
        <Button
            type="primary"
            icon={<FileMarkdownOutlined />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReportAll()}
          >
            {isSmall ? '' : "Bildirimler"}
          </Button>
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/pushNotifications/excel">
          <Button
            type="light"
            icon={<ReloadOutlined />}
            size="large"
            onClick={get}
          >
            Excel İle Hızlı Bildirim Gönder
          </Button>
          </Link>
          <Link to="/pushNotifications/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_NOTIFICATION}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default PushNotifications;
